
import { computed, ref } from "vue"
import { Ref, UnwrapRef } from "@vue/reactivity"
import { useI18n } from "vue-i18n"
import { i18n } from "../../../../locales"

interface Props {
  analysis: Record<string, unknown>
}

class DeductOfItem {
  key: string
  title: string
  factor: string
  analysis: string | null
  proposal: string

  constructor(
    key: string,
    title: string,
    factor: string,
    analysis: string | null,
    proposal: string
  ) {
    this.key = key
    this.title = title
    this.factor = factor
    this.analysis = analysis
    this.proposal = proposal
  }
}

export default {
  props: ["analysis"],
  setup(props: Props) {
    const { t } = useI18n()
    console.log(t("deduct.deductItems.md_start_time_decrease_scale.title"))

    const deductOfItems = computed(() => [
      new DeductOfItem(
        "md_start_time_decrease_scale",
        t("deduct.deductItems.md_start_time_decrease_scale.title"),
        t("deduct.deductItems.md_start_time_decrease_scale.description1"),
        t("deduct.deductItems.md_start_time_decrease_scale.description2"),
        t("deduct.deductItems.md_start_time_decrease_scale.recommendation")
      ),
      new DeductOfItem(
        "md_leave_bed_decrease_scale",
        t("deduct.deductItems.md_leave_bed_decrease_scale.title"),
        t("deduct.deductItems.md_leave_bed_decrease_scale.description1", {
          leaveBedTimes: props.analysis.leaveBedTimes // 描述1，包括动态的 leaveBedTimes
        }),
        t("deduct.deductItems.md_leave_bed_decrease_scale.description2"),
        t("deduct.deductItems.md_leave_bed_decrease_scale.recommendation")
      ),
      new DeductOfItem(
        "md_wake_cnt_decrease_scale",
        t("deduct.deductItems.md_wake_cnt_decrease_scale.title"),
        t("deduct.deductItems.md_wake_cnt_decrease_scale.description1", {
          wakeTimes: props.analysis.wakeTimes
        }),
        t("deduct.deductItems.md_wake_cnt_decrease_scale.description2"),
        t("deduct.deductItems.md_wake_cnt_decrease_scale.recommendation")
      ),
      new DeductOfItem(
        "md_sleep_time_increase_scale",
        t("deduct.deductItems.md_sleep_time_increase_scale.title"),
        t("deduct.deductItems.md_sleep_time_increase_scale.description1"),
        t("deduct.deductItems.md_sleep_time_increase_scale.description2"),
        t("deduct.deductItems.md_sleep_time_increase_scale.recommendation")
      ),
      new DeductOfItem(
        "md_sleep_time_decrease_scale",
        t("deduct.deductItems.md_sleep_time_decrease_scale.title"),
        t("deduct.deductItems.md_sleep_time_decrease_scale.description1"),
        t("deduct.deductItems.md_sleep_time_decrease_scale.description2"),
        t("deduct.deductItems.md_sleep_time_decrease_scale.recommendation")
      ),
      new DeductOfItem(
        "md_perc_deep_decrease_scale",
        t("deduct.deductItems.md_perc_deep_decrease_scale.title"),
        t("deduct.deductItems.md_perc_deep_decrease_scale.description1"),
        t("deduct.deductItems.md_perc_deep_decrease_scale.description2"),
        t("deduct.deductItems.md_perc_deep_decrease_scale.recommendation")
      ),
      new DeductOfItem(
        "md_perc_effective_sleep_decrease_scale",
        t("deduct.deductItems.md_perc_effective_sleep_decrease_scale.title"),
        t(
          "deduct.deductItems.md_perc_effective_sleep_decrease_scale.description1"
        ),
        t(
          "deduct.deductItems.md_perc_effective_sleep_decrease_scale.description2"
        ),
        t(
          "deduct.deductItems.md_perc_effective_sleep_decrease_scale.recommendation"
        )
      ),
      new DeductOfItem(
        "md_heart_stop_decrease_scale",
        t("deduct.deductItems.md_heart_stop_decrease_scale.title"),
        t("deduct.deductItems.md_heart_stop_decrease_scale.description1", {
          heartBeatPauseTimes: props.analysis.heartBeatPauseTimes
        }),
        t("deduct.deductItems.md_heart_stop_decrease_scale.description2"),
        t("deduct.deductItems.md_heart_stop_decrease_scale.recommendation")
      ),
      new DeductOfItem(
        "md_heart_low_decrease_scale",
        t("deduct.deductItems.md_heart_low_decrease_scale.title"),
        t("deduct.deductItems.md_heart_low_decrease_scale.description1", {
          heartBeatRateSlowAllTime: props.analysis.heartBeatRateSlowAllTime
        }),
        t("deduct.deductItems.md_heart_low_decrease_scale.description2"),
        t("deduct.deductItems.md_heart_low_decrease_scale.recommendation")
      ),
      new DeductOfItem(
        "md_heart_high_decrease_scale",
        t("deduct.deductItems.md_heart_high_decrease_scale.title"),
        t("deduct.deductItems.md_heart_high_decrease_scale.description1", {
          heartBeatRateFastAllTime: props.analysis.heartBeatRateFastAllTime
        }),
        t("deduct.deductItems.md_heart_high_decrease_scale.description2"),
        t("deduct.deductItems.md_heart_high_decrease_scale.recommendation")
      ),
      new DeductOfItem(
        "md_breath_stop_decrease_scale",
        t("deduct.deductItems.md_breath_stop_decrease_scale.title"),
        t("deduct.deductItems.md_breath_stop_decrease_scale.description1", {
          breathPauseTimes: props.analysis.breathPauseTimes
        }),
        t("deduct.deductItems.md_breath_stop_decrease_scale.description2"),
        t("deduct.deductItems.md_breath_stop_decrease_scale.recommendation")
      ),
      new DeductOfItem(
        "md_breath_low_decrease_scale",
        t("deduct.deductItems.md_breath_low_decrease_scale.title"),
        t("deduct.deductItems.md_breath_low_decrease_scale.description1", {
          breathRateSlowAllTime: props.analysis.breathRateSlowAllTime
        }),
        t("deduct.deductItems.md_breath_low_decrease_scale.description2"),
        t("deduct.deductItems.md_breath_low_decrease_scale.recommendation")
      ),
      new DeductOfItem(
        "md_breath_high_decrease_scale",
        t("deduct.deductItems.md_breath_high_decrease_scale.title"),
        t("deduct.deductItems.md_breath_high_decrease_scale.description1", {
          breathRateFastAllTime: props.analysis.breathRateFastAllTime
        }),
        t("deduct.deductItems.md_breath_high_decrease_scale.description2"),
        t("deduct.deductItems.md_breath_high_decrease_scale.recommendation")
      )
    ])

    const dialogShow = ref(false)
    const deductOfItem: Ref<UnwrapRef<DeductOfItem | undefined>> = ref(
      undefined
    )
    const show = (key: string) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      deductOfItem.value = deductOfItems.value.find((it) => it.key === key)!!
      dialogShow.value = true
    }
    return {
      deductOfItems,
      dialogShow,
      show,
      deductOfItem
    }
  }
}
