
import { computed, nextTick, watch } from "vue"
import echarts from "echarts"
import { dateToTimeList } from "@/assets/script/utils/date.util"
import { curveDesc } from "@/assets/script/utils/sleep-ace.util"
import { ECHARTS_GRID } from "@/views/report/script/constant"
import { useI18n } from "vue-i18n"

interface Props {
  yAxis: number[]
  startTime: number
}

export default {
  props: [
    "yAxis",
    "startTime",
    "deepSleepPerc",
    "inSleepPerc",
    "lightSleepPerc",
    "wakeSleepPerc"
  ],
  setup(props: Props) {
    const { t } = useI18n()
    const option = computed(() => {
      return {
        grid: ECHARTS_GRID,
        xAxis: [
          {
            type: "category",
            data: dateToTimeList(
              new Date(props.startTime * 1000),
              props.yAxis.length
            ),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: "rgba(255,255,255,.75)" //更改坐标轴文字颜色
              }
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            min: -0.5,
            max: 3.7,
            interval: 0.05,
            inverse: true,
            axisLabel: {
              formatter: curveDesc,
              textStyle: {
                color: "rgba(255,255,255,.75)" //更改坐标轴文字颜色
              }
            },
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          },
          {
            type: "value",
            min: -1,
            max: 4,
            inverse: true,
            axisLabel: {
              show: false
            },
            splitLine: {
              lineStyle: {
                color: "rgba(19, 43, 81, .3)",
                width: 1,
                type: "solid"
              }
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            data: props.yAxis,
            type: "line",
            symbol: "none",
            itemStyle: {
              normal: {
                color: "#5cabd2", //改变折线点的颜色
                lineStyle: {
                  color: "#5cabd2" //改变折线颜色
                }
              }
            },
            areaStyle: {
              color: "rgba(107, 204, 249, .1)"
            }
          }
        ]
      }
    })
    let ref: HTMLDivElement
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let echartsDom: echarts.ECharts
    const setRef = (el: HTMLDivElement) => (ref = el)
    nextTick(() => {
      echartsDom = echarts.init(ref)
      echartsDom.setOption(option.value as echarts.EChartOption)
    })

    watch(
      () => option.value,
      (val) => {
        echartsDom?.setOption(val as echarts.EChartOption)
      }
    )
    return { setRef }
  }
}
