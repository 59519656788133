import httpUtil from "../utils/http.util"
import { Result } from "@/assets/script/model/main.model"
export interface ListSleepDataDto {
  day: number
  equipmentId: string
  leftRight: 0 | 1
  month: number
  year: number
}

export interface ListMonthSleepScoreDto {
  equipmentId: string
  leftRight: 0 | 1
  month: number
  year: number
}

export interface SleepDataVo {
  analysis: object
}

export interface MonthSleepScoreVo {
  time: string
  value: number
}
export class SleepAceService {
  static listSleepData(dto: ListSleepDataDto): Promise<Result<SleepDataVo[]>> {
    return httpUtil.get("/sleepace/listSleepData", dto)
  }

  static getSleepData(id: string): Promise<Result<SleepDataVo>> {
    return httpUtil.get("/sleepace/getSleepData", { id })
  }

  static listMonthSleepScore(
    dto: ListMonthSleepScoreDto
  ): Promise<Result<MonthSleepScoreVo[]>> {
    return httpUtil.get("/sleepace/listMonthSleepScore", dto)
  }

  static getMonthData(dto: ListMonthSleepScoreDto) {
    return httpUtil.get("/sleepace/getMonthData", dto)
  }
}
