
import {
  format as scoreFormat,
  duration
} from "@/assets/script/utils/sleep-ace.util"

export default {
  props: ["analysis", "summary", "currDate"],
  setup() {
    return { scoreFormat, duration }
  }
}
