import { useI18n } from "vue-i18n"
import { i18n } from "../../../../locales"

console.log(i18n.global.tm("sleepAceUtil.averageSleep"))
export function format(score: number): { desc: string; style: string } {
  if (score <= 100 && score >= 90) {
    return {
      // desc: "睡得优秀",
      desc: i18n.global.tm("sleepAceUtil.excellentSleep").toString(),
      style: "#6BFAB9"
    }
  } else if (score >= 80) {
    return {
      // desc: "睡得良好",
      desc: i18n.global.tm("sleepAceUtil.goodSleep").toString(),
      style: "#6BCCF9"
    }
  } else if (score >= 60) {
    return {
      // desc: "睡得一般",
      desc: i18n.global.tm("sleepAceUtil.averageSleep").toString(),
      style: "#FACB57"
    }
  } else if (score > 0) {
    return {
      // desc: "睡得很差",
      desc: i18n.global.tm("sleepAceUtil.poorSleep").toString(),
      style: "#FF4254"
    }
  } else {
    return {
      // desc: "没记录",
      desc: i18n.global.tm("sleepAceUtil.noRecord").toString(),
      style: ""
    }
  }
}

export function duration(section: number): { hours: number; minutes: number } {
  return {
    hours: Math.floor(section / 60),
    minutes: section % 60
  }
}

export function curveDesc(value: number): string {
  if (value === 3.5) {
    // return "深睡"
    return i18n.global.tm("sleepAceUtil.deepSleepPerc").toString()
  } else if (value === 2.5) {
    // return "中睡"
    return i18n.global.tm("sleepAceUtil.inSleepPerc").toString()
  } else if (value === 1) {
    // return "浅睡"
    return i18n.global.tm("sleepAceUtil.lightSleepPerc").toString()
  } else if (value === 0.05) {
    // return "清醒"
    return i18n.global.tm("sleepAceUtil.wakeSleepPerc").toString()
  } else {
    return ""
  }
}
